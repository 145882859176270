<template>
  <b-card>
    <b-col cols="12" sm="3">
      <date-range-picker
        ref="picker"
        v-model="dateRange"
        :ranges="false"
        single-date-picker="range"
        opens="opens-right"
        :auto-apply="false"
      >
        <template v-slot:input="picker">
          {{ picker.startDate && picker.endDate
            ? `${formatDateDDMMYYYY(picker.startDate)} - ${formatDateDDMMYYYY(picker.endDate)}`
            : 'Picker Date' }}
        </template>
      </date-range-picker>
    </b-col>

    <b-col
      class="mt-2 mb-2"
    >
      <h4 v-if="trackings.length === 0">
        No data trackings
      </h4>
      <app-timeline>
        <!-- 12 INVOICES HAVE BEEN PAID -->
        <app-timeline-item
          v-for="tracking in trackings"
          :key="tracking.id"
          :variant="tracking.status ? 'success' : 'danger'"
        >
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>{{`[${tracking.role.name}] ${tracking.actor.email}`}}</h6>
            <small class="text-muted">{{ formatDate(tracking.createdAt) }}</small>
          </div>
          <p>{{ `Activity log description: ${tracking.description}` }}</p>
        </app-timeline-item>
      </app-timeline>
    </b-col>
    <div class="mx-2 mb-2">
      <b-row class="justify-content-md-center">
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalTrackings"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCol, BRow, BPagination,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import DateRangePicker from 'vue2-daterange-picker'
import { ref, watch } from '@vue/composition-api'
import moment from 'moment'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatDate } from '@/utils/utils'

export default {
  components: {
    BPagination,
    BRow,
    BCol,
    DateRangePicker,
    BCard,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    target: {
      type: [String, Number, null],
      required: false,
    },
    actor: {
      type: [String, Number, null],
      required: false,
    },
  },
  setup(props, { emit }) {
    const trackings = ref([])
    const toast = useToast()
    const perPage = ref(10)
    const totalTrackings = ref(0)
    const currentPage = ref(1)
    const dateRange = ref({ startDate: null, endDate: null })
    const fetchTrackings = () => {
      store
        .dispatch('app/fetchTrackings', {
          take: perPage.value,
          page: currentPage.value,
          q: props.target === '' ? null : props.target,
          actor: props.actor === '' ? null : props.actor,
          startTime: dateRange.value.startDate,
          endTime: dateRange.value.endDate,
          sort: 'DESC',
        })
        .then(response => {
          trackings.value = response.body.data
          totalTrackings.value = response.body.meta.itemCount
        })
        .catch(e => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching trackings',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    fetchTrackings()
    watch([perPage, currentPage, dateRange], () => {
      fetchTrackings()
    }, {
      deep: true,
    })
    return {
      trackings,
      perPage,
      totalTrackings,
      currentPage,
      dateRange,
      formatDate,
    }
  },
  methods: {
    formatDateDDMMYYYY(time) {
      return moment(time).format('DD/MM/YYYY')
    },
  },
}
</script>

<style>

</style>
